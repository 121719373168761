var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"width":"950"},model:{value:(_vm.dialogAnexos),callback:function ($$v) {_vm.dialogAnexos=$$v},expression:"dialogAnexos"}},[(_vm.anexosSelected)?_c('v-card',{attrs:{"width":"100%","min-height":"400"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v(" Anexos ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","no-data-text":"Não há anexos disponíveis","headers":_vm.anexosHeaders,"items":_vm.anexosSelected},scopedSlots:_vm._u([{key:"item.blob_size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAnexoSize(item.blob_size))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.handleOpenAnexo(item.id)}}},[_vm._v("mdi-cloud-download")])]}}],null,false,1247380655)})],1)],1)],1)],1):_vm._e()],1),_c('v-dialog',{model:{value:(_vm.excluirParcelasDialog),callback:function ($$v) {_vm.excluirParcelasDialog=$$v},expression:"excluirParcelasDialog"}},[_c('v-card',{attrs:{"min-height":"400"}},[_c('v-card-title',{staticClass:"headline primary--text"},[_vm._v(" Escolha quais parcelas serão excluídas ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"ma-1",attrs:{"multi-sort":"","headers":_vm.excluirParcelasHeaders,"items":_vm.excluirParcelasOptions,"fixed-header":"","height":"50vh","dense":"","footer-props":{
            itemsPerPageOptions: [-1]
          },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.checked",fn:function(ref){
          var item = ref.item;
return [_c('v-checkbox',{attrs:{"dense":"","disabled":item.financeiro_parcela_status_id == _vm.getFinanceiroParcelaStatusIdPago()},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusFinanceiroParcelaStatus(item.financeiro_parcela_status_id).color}},[_vm._v(" "+_vm._s(_vm.getStatusFinanceiroParcelaStatus(item.financeiro_parcela_status_id).name)+" ")])]}},{key:"item.valor_integral",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_integral))+" ")]}},{key:"item.parcela_numero",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.parcela_numero)+"/"+_vm._s(item.financeiro_numero_parcelas))])]}},{key:"item.valor_atualizado",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_atualizado))+" ")]}},{key:"item.valor_pago",fn:function(ref){
          var item = ref.item;
return [_c('FinanceiroValorPagoTextComponent',{attrs:{"propItem":item}})]}},{key:"item.vencimento",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vencimento))+" ")]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmDeleteItem()}}},[_vm._v(" Confirmar ")]),_c('v-btn',{on:{"click":function($event){_vm.excluirParcelasDialog = false}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Contas a pagar")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":_vm.isMobile ? '' : 400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"30","color":"black","right":""}},'v-icon',attrs,false),on),[_vm._v("info")])]}}])},[_c('span',{staticClass:"articulat-medium",staticStyle:{"font-size":"16px"}},[_c('b',[_vm._v("Total de despesas do mês")]),_vm._v(" - filtrar o mês desejado, manter todas as categorias selecionadas, deixa apenas o status pago selecionado e deixar desmarcado completamente a vencer e vencido neste mês. ")])]),_c('v-spacer'),(_vm.allowCreate)?_c('v-btn',{attrs:{"to":"/main/FinanceiroContasapagar/create","color":"primary"}},[_vm._v("Criar")]):_vm._e()],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{attrs:{"prop-title":"Data de vencimento"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('SaveButton',{ref:"loadingButton",attrs:{"prop-show-voltar":false,"propSubmitLabel":"Buscar","propTooltipMsg":"Para visualizar totalizadores, arraste a tela até embaixo e clique em carregar mais."},on:{"submit":function($event){return _vm.buscar()}}})],1)],1),(_vm.userActionsVisualizarTotalizadoresAllow)?_c('v-row',[_c('v-col',[_c('v-row',[(_vm.totals.por_financeiro_categoria_vencimento_entre)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"propAppend":false,"prop-title":("Por categoria - Com vencimento entre " + (_vm.formatDate(_vm.selectPeriodDate[0])) + " - " + (_vm.formatDate(_vm.selectPeriodDate[1]))),"prop-items":_vm.totals.por_financeiro_categoria_vencimento_entre},scopedSlots:_vm._u([{key:"item.temp_receber_valor_integral_sem_desconto",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor integral (Sem desconto)"]))+" ")]}},{key:"item.temp_receber_valor_com_desconto",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor com desconto"]))+" ")]}},{key:"item.temp_receber_valor_atualizado",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor atualizado"]))+" ")]}},{key:"item.temp_valor_recebido",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["Valor pago"]))+" ")]}}],null,true)})],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.userActionsVisualizarTotalizadoresAllow)?_c('v-row',[_c('v-col',[_c('v-row',[(_vm.totals.por_status)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"propAppend":false,"prop-title":("Por Status - Com vencimento entre " + (_vm.formatDate(_vm.selectPeriodDate[0])) + " - " + (_vm.formatDate(_vm.selectPeriodDate[1]))),"prop-items":_vm.totals.por_status},scopedSlots:_vm._u([{key:"item.temp_receber_valor_integral_sem_desconto",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor integral (Sem desconto)"]))+" ")]}},{key:"item.temp_receber_valor_com_desconto",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor com desconto"]))+" ")]}},{key:"item.temp_receber_valor_atualizado",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor atualizado"]))+" ")]}},{key:"item.temp_valor_recebido",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["Valor pago"]))+" ")]}}],null,true)})],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.userActionsVisualizarTotalizadoresAllow)?_c('v-row',[(_vm.totals.por_financeiro_categoria_a_vencer)?_c('v-col',{attrs:{"cols":"6","xs":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"pt-2 pb-0"},[_vm._v(" Por categoria - a vencer neste mês ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.totals.por_financeiro_categoria_a_vencer.items,"headers":_vm.headerVencido,"dense":""},scopedSlots:_vm._u([{key:"item.temp_receber_valor_integral_sem_desconto",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor integral (Sem desconto)"]))+" ")]}},{key:"item.temp_receber_valor_com_desconto",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor com desconto"]))+" ")]}},{key:"item.temp_receber_valor_atualizado",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor atualizado"]))+" ")]}},{key:"item.temp_valor_recebido",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["Valor pago"]))+" ")]}},{key:"body.append",fn:function(ref){
          var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(h,idx){return _c('td',{key:idx},[(idx === 0)?_c('b',[_vm._v("Total")]):_c('b',[_vm._v(" "+_vm._s(_vm.sumField(_vm.totals.por_financeiro_categoria_a_vencer.items, h.text))+" ")])])}),0)]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.totals.por_financeiro_categoria_vencido)?_c('v-col',{attrs:{"cols":"6","xs":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"pt-2 pb-0"},[_vm._v(" Por categoria - vencido neste mês ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.totals.por_financeiro_categoria_vencido.items,"headers":_vm.headerVencido,"dense":""},scopedSlots:_vm._u([{key:"item.temp_receber_valor_integral_sem_desconto",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor integral (Sem desconto)"]))+" ")]}},{key:"item.temp_receber_valor_com_desconto",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor com desconto"]))+" ")]}},{key:"item.temp_receber_valor_atualizado",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["A pagar - Valor atualizado"]))+" ")]}},{key:"item.temp_valor_recebido",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item["Valor pagar"]))+" ")]}},{key:"body.append",fn:function(ref){
          var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(h,idx){return _c('td',{key:idx},[(idx === 0)?_c('b',[_vm._v("Total")]):_c('b',[_vm._v(" "+_vm._s(_vm.sumField(_vm.totals.por_financeiro_categoria_vencido.items, h.text))+" ")])])}),0)]}}],null,true)})],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.totals.por_status)?_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_contas_pagar","propSum":"","customSort":_vm.customSort,"propCustomSort":true},scopedSlots:_vm._u([{key:"item.entidade_nome",fn:function(ref){
          var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.entidade_nome)),"propObjectId":item.entidade_id,"propToolName":item.entidade_tipo}})]}},{key:"item.vencimento",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vencimento))+" ")]}},{key:"item.data_emissao",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_emissao))+" ")]}},{key:"item.observacao",fn:function(ref){
          var value = ref.value;
return [_c('text-expandable-component',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.financeiro_parcela_status_nome",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{class:_vm.getStatusFinanceiroParcelaStatus(item.financeiro_parcela_status_id).color,attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getStatusFinanceiroParcelaStatus(item.financeiro_parcela_status_id).name)+" ")])]}},{key:"item.valor_integral",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item.valor_integral))+" ")]}},{key:"item.valor_atualizado",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item.valor_atualizado))+" ")]}},{key:"item.valor_original",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item.valor_original))+" ")]}},{key:"item.valor_pago",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(item.valor_pago))+" ")]}},{key:"item.origem",fn:function(ref){
          var item = ref.item;
return [(item.origem)?_c('div',{staticStyle:{"margin-top":"4px","margin-bottom":"4px"}},[(item.origem.conta_bancaria_ids)?_c('div',_vm._l((item.origem.conta_bancaria_ids),function(conta_bancaria,idx){return _c('div',{key:idx},[(conta_bancaria.id)?_c('v-row',[_c('v-col',[(_vm.allowViewContaBancaria && conta_bancaria.id)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.openContaBancaria(conta_bancaria.id)}}},[_vm._v(" "+_vm._s(conta_bancaria.nome)+" ")]):_vm._e()],1)],1):_vm._e()],1)}),0):_vm._e(),(item.origem.conta_bancaria_ids)?_c('div',_vm._l((item.origem.caixa_ids),function(caixa,idx){return _c('div',{key:idx},[(caixa.id)?_c('v-row',[_c('v-col',[(_vm.allowViewCaixa)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.openCaixa(caixa.id)}}},[_vm._v(" "+_vm._s(caixa.nome)+" ")]):_vm._e()],1)],1):_vm._e()],1)}),0):_vm._e()]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.allowEdit)?_c('v-icon',{on:{"click":function($event){return _vm.routeEdit(item.id)}}},[_vm._v("mdi-eye")]):_vm._e(),(item.tem_anexos)?_c('v-icon',{on:{"click":function($event){return _vm.openAnexoDialog(item.id)}}},[_vm._v("mdi-paperclip")]):_vm._e(),(_vm.allowDelete && item.financeiro_parcela_status_id == 1 && item.valor_pago === 0)?_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }